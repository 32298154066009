/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withNamespaces} from 'react-i18next';
import {REGISTER_ACTION, CLEAR_AUTH_ACTION} from 'app/actions/authAction';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import {HTTP_REQUEST_EMAIL_VERIFY} from 'app/services/httpClient/auth.service';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import HelmetMaker from 'app/utils/Helmet';
import ReactGA from 'react-ga4';
import Container from '@mui/material/Container';
import Cookies from 'js-cookie';
import Divider from '@mui/material/Divider';
import {toast} from 'react-toastify';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import withRouter from 'app/utils/withRouter';
import {GoogleLogin, hasGrantedAllScopesGoogle} from '@react-oauth/google';
import MicrosoftLogin from 'react-microsoft-login';

const moment = require('moment');

function mapStateToProps(state) {
  return {
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_REGISTER: (payload, authtype) => dispatch(REGISTER_ACTION(payload, authtype)),
    DISPATCH_CLEAR_AUTH: () => dispatch(CLEAR_AUTH_ACTION()),
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
  };
}

const {REACT_APP_MICROSOFT_CLIENT_ID} = process.env;

class FreeTrialField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailerror: false,
      helperText: undefined,
      authtype: undefined,
      submitting: false,
      Payload: {
        firstName: undefined,
        lastName: undefined,
        fullName: undefined,
        nameerror: false,
        businessName: undefined,
        jobTitle: undefined,
        agreeTerms: false,
        email: undefined,
        phone: undefined,
        password: undefined,
        confirm_password: undefined,
        Payload: {},
        sub_id: 'undefined',
        sub_created: 'undefined',
        current_period_end: 'undefined',
        current_period_start: 'undefined',
        stripeCustomerId: 'undefined',
        cancel_at: 'undefined',
        cancel_at_period_end: false,
        canceled_at: 'undefined',
        paymentMethod_id: 'undefined',
        paymentMethod_brand: 'undefined',
        last4: 'undefined',
        exp_month: 'undefined',
        status: 'undefined',
        max_profiles: 1,
        exp_year: 'undefined',
        nameOnCard: 'undefined',
        billingAddress: 'undefined',
        city: 'undefined',
        postCodeZip: 'undefined',
        country: 'undefined',
        subscription: 'free',
        fontLoaded: false,
        mailbox: {
          user: null,
          password: {},
          outgoinghost: null,
          incominghost: null,
        },
      },
    };
    // bind event handlers in class components.
    this.HandleInputChange = this.HandleInputChange.bind(this);
    this.HandleCheckboxChange = this.HandleCheckboxChange.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.HandleMSAuth = this.HandleMSAuth.bind(this);
  }

  componentDidMount() {
    const {GET_ERRORS_STATE, DISPATCH_CLEAR_AUTH, GET_AUTH_STATE, navigate} = this.props;
    const {isAuthenticated, token} = GET_AUTH_STATE;
    if (isAuthenticated && token !== null) {
      navigate('/complete');
    }
    if (GET_ERRORS_STATE.length !== 0) {
      toast.error(GET_ERRORS_STATE);
      DISPATCH_CLEAR_AUTH();
    }
    if ('fonts' in document) {
      document.fonts.load('14px Google Sans').then(() => {
        this.setState({fontLoaded: true});
      });
    }
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentDidUpdate(prevState) {
    const {GET_ERRORS_STATE, GET_AUTH_STATE} = this.props;
    if (
      GET_AUTH_STATE &&
      GET_AUTH_STATE.isAuthenticated !== prevState.GET_AUTH_STATE.isAuthenticated
    ) {
      // console.log('AUTH CHANGE', this.state.authtype);
      // console.log('REGISTER', reg);
      this.props.navigate('/success/?m=free-trial');
    }
    // if (prevState !== this.state) {
    //   ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    //     if (value !== this.state.Payload.password) {
    //       return false;
    //     }
    //     return true;
    //   });
    // }
    // if (GET_ERRORS_STATE.length !== 0) {
    //   console.log('GET ERRORS', GET_ERRORS_STATE);
    //   toast.error(GET_ERRORS_STATE[0].message);
    // }
  }

  HandleInputChange(event) {
    const date = moment().unix();
    const future = moment().add(1, 'M').unix();
    this.setState({
      emailerror: false,
      submitting: false,
      helperText: undefined,
      Payload: {
        ...this.state.Payload,
        [event.target.name]: event.target.value,
        emailerror: false,
        current_period_start: date,
        current_period_end: future,
      },
    });
  }

  HandleCheckboxChange(event) {
    toast.dismiss();
    if (this.state.Payload.agreeTerms === false) {
      this.setState({
        Payload: {
          ...this.state.Payload,
          agreeTerms: true,
        },
      });
    } else {
      this.setState({
        Payload: {
          ...this.state.Payload,
          agreeTerms: false,
        },
      });
    }
  }

  async HandleSubmit(event) {
    event.preventDefault();
    this.setState({submitting: true});
    try {
      this.setState({authtype: 'Email'});
      function validateEmail(email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }
      // function validateEmail(email) {
      //   const re = /\S+@\S+\.\S+/;
      //   return re.test(email);
      // }
      if (!this.state.Payload.email) {
        // toast.error('You must agree with our Terms and Conditions to register.');
        toast.error('You must enter an email to access your free trial.');
        this.setState({
          emailerror: true,
          submitting: false,
          helperText: 'Email required',
        });
      } else if (!validateEmail(this.state.Payload.email)) {
        // console.log('BAD EMAIL');
        this.setState({
          emailerror: true,
          submitting: false,
          helperText: 'Valid email required',
        });
        toast.error('You must use a valid email to access your free trial.');
      } else {
        // const firstName = '';
        // const lastName = '';
        // const name = this.state.Payload.fullName.trim();
        // const all = name.split(' ').length;
        // if (all < 2) {
        //   // setLoading(false);
        //   // setNameError(true);
        //   this.setState({
        //     nameerror: true,
        //   });
        //   window.scrollTo(0, 60);
        //   toast.error('Please enter your full name');
        // } else {
        const {DISPATCH_REGISTER} = this.props;
        await DISPATCH_REGISTER(this.state.Payload, 'Email').catch((error) => {
          // handle error
          this.setState({submitting: false});
          console.log('DISPATCH REGISTER ERROR', error);
        });
        // console.log('REGISTER RESPONSE', reg);
        // const {DISPATCH_PROFILE, GET_AUTH_STATE} = this.props;
        // DISPATCH_PROFILE(GET_AUTH_STATE.token);
        // this.props.navigate('/leadsearch?m=confirm-email');
        // }
      }
    } catch (e) {
      this.setState({submitting: false});
      console.error('Registration error', e);
    }
  }

  HandleMSAuth(err, data, msal) {
    // console.log('MICROSOFT LOGIN', err, data, msal, sessionStorage, localStorage);
    const {DISPATCH_REGISTER} = this.props;
    this.setState({authtype: 'Microsoft'});
    if (data) {
      DISPATCH_REGISTER(data, 'Microsoft');
    }
    // sessionStorage.removeItem("msal.idtoken")
    // sessionStorage.removeItem("msal.idtoken")
    Cookies.remove('msal.idtoken');
    // console.log('MICROSOFT LOGIN 1', sessionStorage, localStorage);
  }

  render() {
    const {t: lang, navigate} = this.props;
    // const authHandler = (err, data) => {
    //   console.log('MICROSOFT LOGIN', err, data);
    // };
    // console.log('HERE ARE THE PROPS', this.props);
    return (
      <Button
      onClick={() => this.props.navigate("/start-free")}
      variant="contained"
      style={{
        backgroundColor: "#003671", // Brand colour
        borderRadius: "25px",
        color: "white",
        padding: "10px 20px",
        fontSize: "16px",
        fontWeight: "bold",
        textTransform: "none",
        boxShadow: "none",
        display: "flex",
        alignItems: "center",
      }}
    >
      Start Free
      <span
        style={{
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        →
      </span>
    </Button>
      // <Fragment>
      //   {/* <HelmetMaker props={helmetload} /> */}
      //   {/* <Container
      //     // className="freetrial-container"
      //     component="main"
      //     // maxWidth="sm"
      //     style={{paddingBottom: '200px', width: '100%'}}> */}
      //   {/* <div className="regpaper"> */}
      //   {/* <Grid container> */}
      //   <Hidden smDown>
      //     <Grid
      //       container
      //       direction="column"
      //       alignItems="center"
      //       justifyContent="center"
      //       style={{padding: '0px'}}>
      //       {/* <Grid item > */}
      //       <ValidatorForm
      //         id="regform"
      //         style={{width: '384px'}}
      //         onError={(errors) => {
      //           if (typeof window !== 'undefined') {
      //             window.scrollTo(0, 260), toast.error(errors);
      //           }
      //         }}
      //         onSubmit={this.HandleSubmit}>
      //         <CssBaseline />
      //         {/* <Typography
      //                 className="registerheader"
      //                 component="h1"
      //                 variant="h1"
      //                 style={{fontWeight: '400', paddingBottom: '48px'}}>
      //                 Start your free trial
      //               </Typography> */}
      //         {/* <Grid container spacing={2}> */}
      //         <Grid item xs={12} style={{height: '70px'}}>
      //           <TextValidator
      //             variant="outlined"
      //             fullWidth
      //             onChange={this.HandleInputChange}
      //             id="email"
      //             label="Enter email"
      //             name="email"
      //             error={this.state.emailerror}
      //             helperText={
      //               <span
      //                 style={{
      //                   backgroundColor: '#fafafa',
      //                   padding: this.state.helperText ? '0px 4px' : '0px',
      //                   marginLeft: '-4px',
      //                 }}>
      //                 {this.state.helperText}
      //               </span>
      //             }
      //             value={this.state.Payload.email}
      //             autoComplete="email"
      //             // validators={['required', 'isEmail']}
      //             // errorMessages={['This field is required', 'Email is not valid']}
      //             sx={{
      //               '& .MuiInputLabel-shrink': {
      //                 marginLeft: '-10px',
      //                 paddingRight: '16px',
      //                 marginTop: '2px',
      //                 backgroundColor: 'white',
      //                 borderRadius: '25px',
      //                 // display: this.props.color === 'blue' ? 'none' : '',
      //               },
      //               '& .MuiFormHelperText-root': {
      //                 zIndex: '2',
      //                 marginTop: '-11px',
      //                 paddingLeft: '14px',
      //               },
      //               '& .MuiInputBase-root': {
      //                 borderRadius: '25px',
      //                 paddingRight: '8px',
      //                 paddingLeft: '8px',
      //                 fontSize: '14px',
      //                 backgroundColor: 'white',
      //               },
      //               // '& .MuiOutlinedInput': {color: 'green !important'},
      //             }}
      //             InputProps={{
      //               endAdornment: (
      //                 <Button
      //                   type="submit"
      //                   form="regform"
      //                   // onClick={this.HandleSubmit}
      //                   fullWidth
      //                   variant="contained"
      //                   disabled={this.state.submitting}
      //                   color="primary"
      //                   style={{
      //                     borderRadius: '25px',
      //                     color: 'white',
      //                     textTransform: 'capitalize',
      //                     maxWidth: '102px',
      //                   }}
      //                   // className="registersubmit"
      //                 >
      //                   {this.state.submitting ? 'Registering' : 'Start Free'}
      //                 </Button>
      //               ),
      //             }}
      //             InputLabelProps={{
      //               style: {
      //                 paddingLeft: '14px',
      //               },
      //             }}
      //           />
      //         </Grid>
      //         {/* </Grid> */}
      //         <Grid container justifyContent="center" style={{textAlign: 'center'}}>
      //           <Grid item xs={12}>
      //             <Typography
      //               variant="body2"
      //               paragraph
      //               align="justify"
      //               style={{
      //                 textAlign: 'center',
      //                 fontSize: '14px',
      //                 color: this.props.color === 'blue' ? '#ffffff' : '#000000',
      //                 marginLeft: '-12px',
      //               }}>
      //               <CreditCardOffIcon style={{marginBottom: '-7px'}} />
      //               {'  '}No card needed
      //               <span style={{margin: '0px 12px'}}>|</span>
      //               <TaskAltIcon style={{marginBottom: '-7px'}} />
      //               {'  '}Data compliant
      //             </Typography>
      //             <Grid container>
      //               <Grid item xs={12} sm={6}>
      //                 <GoogleLogin
      //                   size="medium"
      //                   shape="circle"
      //                   width="180px"
      //                   style={{
      //                     fontSize: '14px',
      //                     fontWeight: '400',
      //                     letterSpacing: '0.25px',
      //                     // visibility: this.state.fontLoaded ? 'visible' : 'hidden !important',
      //                   }}
      //                   // text="signup_with"
      //                   logo_alignment="left"
      //                   onSuccess={(credentialResponse) => {
      //                     // console.log('GOOGLE LOGIN', credentialResponse);
      //                     const {DISPATCH_REGISTER} = this.props;
      //                     this.setState({authtype: 'Google'});
      //                     DISPATCH_REGISTER(credentialResponse, 'Google').then();
      //                   }}
      //                   onError={() => {
      //                     console.log('Login Failed');
      //                   }}
      //                 />
      //               </Grid>
      //               <Grid item xs={12} sm={6}>
      //                 {typeof window !== 'undefined' && (
      //                   <MicrosoftLogin
      //                     clientId={REACT_APP_MICROSOFT_CLIENT_ID}
      //                     authCallback={(err, data, msal) => this.HandleMSAuth(err, data, msal)}
      //                     // text="signup_with"
      //                     // useLocalStorageCache
      //                     // className='mslogin'
      //                     // buttonTheme='light_short'
      //                   >
      //                     <Button
      //                       type="ms"
      //                       // form=""
      //                       fullWidth
      //                       variant="contained"
      //                       // color="primary"
      //                       startIcon={
      //                         <Avatar
      //                           variant="square"
      //                           style={{
      //                             width: '20px',
      //                             height: '20px',
      //                             marginLeft: '0px',
      //                           }}
      //                           alt="Microsoft-logo"
      //                           src="https://learn.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.svg"
      //                         />
      //                       }
      //                       style={{
      //                         borderRadius: '25px',
      //                         color: 'black',
      //                         // backgroundColor: 'white',
      //                         textTransform: 'none',
      //                         padding: '4px',
      //                         fontSize: '13px',
      //                         // fontWeight: '600',
      //                         boxShadow: 'none',
      //                         // fontFamily: 'Segoe UI, Open Sans',
      //                         marginLeft: '0px',
      //                         // maxWidth: '101px',
      //                       }}
      //                       sx={{
      //                         border: '1px solid #dadce0',
      //                         backgroundColor: '#fff',
      //                         '&:hover': {
      //                           backgroundColor: '#F8FAFE',
      //                           borderColor: '#d2e3fc',
      //                         },
      //                       }}
      //                       // className="registersubmit"
      //                     >
      //                       Sign up with Microsoft
      //                     </Button>
      //                   </MicrosoftLogin>
      //                 )}
      //               </Grid>
      //             </Grid>
      //             {/* <Typography
      //             variant="body2"
      //             paragraph
      //             align="justify"
      //             style={{textAlign: 'center', fontSize: '0.8rem'}}>
      //             <TaskAltIcon /> Data compliant
      //           </Typography> */}
      //           </Grid>
      //           {/* <Grid item xs={12}>
      //                   <Typography
      //                     variant="body2"
      //                     paragraph
      //                     align="justify"
      //                     style={{
      //                       textAlign: 'center',
      //                       fontSize: '12px',
      //                       padding: '0px',
      //                     }}>
      //                     By clicking 'Start trial' you agree to our{' '}
      //                     <Link
      //                       target="_blank"
      //                       href="/terms"
      //                       variant="body1"
      //                       align="left"
      //                       style={{fontSize: '12px'}}
      //                       className="pagelink">
      //                       Terms
      //                     </Link>{' '}
      //                     and{' '}
      //                     <Link
      //                       target="_blank"
      //                       href="/privacy"
      //                       variant="body1"
      //                       style={{fontSize: '12px'}}
      //                       align="left"
      //                       className="pagelink">
      //                       Privacy Policy
      //                     </Link>
      //                   </Typography>
      //                 </Grid>
      //                 <Grid item xs={12} style={{textAlign: 'center'}}>
      //                   <Link
      //                     style={{cursor: 'pointer', fontSize: '12px'}}
      //                     onClick={() => {
      //                       this.props.navigate('login');
      //                     }}
      //                     variant="body2">
      //                     Already have an account? Sign in
      //                   </Link>
      //                 </Grid> */}
      //         </Grid>
      //       </ValidatorForm>
      //     </Grid>
      //   </Hidden>
      //   <Hidden smUp>
      //     <Grid
      //       container
      //       direction="column"
      //       alignItems="center"
      //       justifyContent="center"
      //       style={{padding: '0px'}}>
      //       {/* <Grid item > */}
      //       <ValidatorForm
      //         id="regform"
      //         onError={(errors) => {
      //           if (typeof window !== 'undefined') {
      //             window.scrollTo(0, 260), toast.error(errors);
      //           }
      //         }}
      //         onSubmit={this.HandleSubmit}>
      //         <CssBaseline />
      //         {/* <Typography
      //                 className="registerheader"
      //                 component="h1"
      //                 variant="h1"
      //                 style={{fontWeight: '400', paddingBottom: '48px'}}>
      //                 Start your free trial
      //               </Typography> */}
      //         {/* <Grid container spacing={2}> */}
      //         <Grid item xs={12} style={{height: '70px', maxWidth: '300px', margin: 'auto'}}>
      //           <TextValidator
      //             variant="outlined"
      //             fullWidth
      //             onChange={this.HandleInputChange}
      //             id="email"
      //             label="Enter email"
      //             name="email"
      //             error={this.state.emailerror}
      //             helperText={
      //               <span
      //                 style={{
      //                   backgroundColor: '#fafafa',
      //                   padding: this.state.helperText ? '0px 4px' : '0px',
      //                   // marginLeft: '-4px',
      //                 }}>
      //                 {this.state.helperText}
      //               </span>
      //             }
      //             value={this.state.Payload.email}
      //             autoComplete="email"
      //             // validators={['required', 'isEmail']}
      //             // errorMessages={['This field is required', 'Email is not valid']}
      //             sx={{
      //               '& .MuiInputLabel-shrink': {
      //                 marginLeft: '-10px',
      //                 paddingRight: '16px',
      //                 marginTop: '2px',
      //                 backgroundColor: 'white',
      //                 borderRadius: '25px',
      //                 // display: this.props.color === 'blue' ? 'none' : '',
      //               },
      //               '& .MuiFormHelperText-root': {
      //                 zIndex: '2',
      //                 marginTop: '-11px',
      //                 paddingLeft: '14px',
      //               },
      //               '& .MuiInputBase-root': {
      //                 borderRadius: '25px',
      //                 paddingRight: '8px',
      //                 paddingLeft: '8px',
      //                 fontSize: '14px',
      //                 backgroundColor: 'white',
      //               },
      //               // '& .MuiOutlinedInput': {color: 'green !important'},
      //             }}
      //             InputProps={{
      //               endAdornment: (
      //                 <Button
      //                   type="submit"
      //                   form="regform"
      //                   // onClick={this.HandleSubmit}
      //                   disabled={this.state.submitting}
      //                   fullWidth
      //                   variant="contained"
      //                   color="primary"
      //                   style={{
      //                     borderRadius: '25px',
      //                     color: 'white',
      //                     textTransform: 'capitalize',
      //                     maxWidth: '101px',
      //                   }}
      //                   // className="registersubmit"
      //                 >
      //                   {this.state.submitting ? 'Registering' : 'Start'}
      //                 </Button>
      //               ),
      //             }}
      //             InputLabelProps={{
      //               style: {
      //                 paddingLeft: '14px',
      //               },
      //             }}
      //           />
      //         </Grid>
      //         {/* </Grid> */}
      //         <Grid container justifyContent="center" style={{textAlign: 'center'}}>
      //           <Grid item xs={12}>
      //             <Typography
      //               variant="body2"
      //               paragraph
      //               align="justify"
      //               style={{
      //                 textAlign: 'center',
      //                 fontSize: '12px',
      //                 color: this.props.color === 'blue' ? '#ffffff' : '#000000',
      //                 // marginLeft: '-2px',
      //               }}>
      //               <CreditCardOffIcon style={{marginBottom: '-7px'}} />
      //               {'  '}No card needed
      //               <span style={{margin: '0px 12px'}}>|</span>
      //               <TaskAltIcon style={{marginBottom: '-7px'}} />
      //               {'  '}Data compliant
      //             </Typography>
      //             <Grid container />
      //           </Grid>
      //         </Grid>
      //       </ValidatorForm>
      //     </Grid>
      //   </Hidden>
      // </Fragment>
    );
  }
}

FreeTrialField.propTypes = {
  DISPATCH_REGISTER: PropTypes.func.isRequired,
  DISPATCH_PROFILE: PropTypes.func.isRequired,
  DISPATCH_CLEAR_AUTH: PropTypes.func.isRequired,
  GET_ERRORS_STATE: PropTypes.array.isRequired,
  GET_AUTH_STATE: PropTypes.object.isRequired,
  // // t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FreeTrialField));
